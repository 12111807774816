
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.topFixedBanner{
    background-image: url(../images/TopBanner.jpg);
    min-height: 450px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    background-position-y: top;
}

.topBannerOverlay{
    position: absolute;
    min-height: 450px;
    width: 100%;
    background:rgba(0, 0, 0, 0.3);   
}

.cardTextAlignment {
    text-align: center;
   
}

.cardTitle {
    text-align: center;
    color:rgb(0, 179, 250);
}

.clinicalActivitiesTitle{
    color: #3499fd;
    margin-top: 1rem;
    margin-bottom:  1rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
 
}

.navTitle{
   
    font-size: 24px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFFFFF !important;
}

.navLogo{
    background-image: url(../../logo.svg);
    font-size: 24px;
   
}

.navTitleScroll{
    font-size: 24px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #0073E6 !important;
}

.navBarBgColorScroll{
    background-color: white;
}

.navMenu{
    font-weight: 700;

}

.navMenuScroll{
    font-weight: 700;
    color:black !important;
}

.navLogo{
    background-image: url(../../logo.svg);
    min-height: 50;
    min-width: 60;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    background-position-y: top;
}


.topBannerH2{
    margin-top: 20rem;
    color:rgb(255, 255, 255);
    text-align: center;   
    
}




