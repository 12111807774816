.footer-head{
    font-size:20px;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:bold;
}
.footer-text{
    font-size:13px;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:300;
}

.mapouter{
    position:relative;
    text-align:right;
    height:500px;
    width:600px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:600px;
}
.bgColor{
    background-color:rgb(rgb(121, 121, 121), rgb(199, 199, 199), rgb(189, 189, 255));
}

.footerNavigation{
   
    color:rgb(255, 255, 255);
    font-weight: bold;    
}
