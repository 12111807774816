@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.topFixedBanner{
    background-image: url(/static/media/TopBanner.2425ca59.jpg);
    min-height: 450px;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    background-position-y: top;
}

.topBannerOverlay{
    position: absolute;
    min-height: 450px;
    width: 100%;
    background:rgba(0, 0, 0, 0.3);   
}

.cardTextAlignment {
    text-align: center;
   
}

.cardTitle {
    text-align: center;
    color:rgb(0, 179, 250);
}

.clinicalActivitiesTitle{
    color: #3499fd;
    margin-top: 1rem;
    margin-bottom:  1rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
 
}

.navTitle{
   
    font-size: 24px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFFFFF !important;
}

.navLogo{
    background-image: url(/static/media/logo.ff9d87a0.svg);
    font-size: 24px;
   
}

.navTitleScroll{
    font-size: 24px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #0073E6 !important;
}

.navBarBgColorScroll{
    background-color: white;
}

.navMenu{
    font-weight: 700;

}

.navMenuScroll{
    font-weight: 700;
    color:black !important;
}

.navLogo{
    background-image: url(/static/media/logo.ff9d87a0.svg);
    min-height: 50;
    min-width: 60;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    background-position-y: top;
}


.topBannerH2{
    margin-top: 20rem;
    color:rgb(255, 255, 255);
    text-align: center;   
    
}





.footer-head{
    font-size:20px;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:bold;
}
.footer-text{
    font-size:13px;
    font-family:'Roboto Condensed', sans-serif;
    font-weight:300;
}

.mapouter{
    position:relative;
    text-align:right;
    height:500px;
    width:600px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:500px;
    width:600px;
}
.bgColor{
    background-color:rgb(rgb(121, 121, 121), rgb(199, 199, 199), rgb(189, 189, 255));
}

.footerNavigation{
   
    color:rgb(255, 255, 255);
    font-weight: bold;    
}

.DoctorsTitle{
    color: #3499fd;
    margin-top: .3rem;
    margin-bottom:  .3rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
 
}

.DoctorMargin{
   
    margin-right: 5rem;
    margin-left:  5rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
   
 
}
:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: #1888ff;
  background-color: var(--primary);
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #1888ff;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

.dropdown-menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #1888ff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

