.DoctorsTitle{
    color: #3499fd;
    margin-top: .3rem;
    margin-bottom:  .3rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: bold;
 
}

.DoctorMargin{
   
    margin-right: 5rem;
    margin-left:  5rem;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Montserrat';
   
 
}